.instructions {
    background: #f3f3f3;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px 15px;
    width: 50%;
    /* margin-left: 100px; */
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.instruction-or {
    font-size: 1rem;
    
  }
  /* Style for the Modal */
.ant-modal {
    max-width: 600px;
  }
  
  /* Style for the instruction container */
  .instruction-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  /* Style for the instruction item */
  .instruction-item {
    display: flex;
    gap: 10px;
  }
  
  /* Style for the instruction index */
  .instruction-index {
    font-weight: bold;
    font-size: 0.9rem;
    color: black;
  }
  
  /* Style for the instruction text */
  .instruction-text {
    font-size: 1rem;
  }
  .instruction-or {
    font-size: 0.5rem;
    text-align: center
  }


  